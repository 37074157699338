import React from 'react';
import { Route } from 'react-router-dom';

import LandingPage from 'containers/LandingPage';
import CJT from 'containers/CJT';
import { Products, Orders, Simulation, NewBuying, BuySell } from 'containers/Quarry';
import Hosting from 'containers/Hosting';
import TestSuites from 'containers/TestSuites';
import CreateTestSuite from 'containers/TestSuites/CreateTestSuite';
import JobsQueue from 'containers/JobsQueue/JobsQueue';
import Transaction from 'containers/Transaction';
import Submit from 'containers/Submit';
import EndToEndContent from 'containers/E2E/MeshContent';
import TestDataContent from 'containers/TestData/QuarryContent';
import withSubcategory from 'hocs/withSubcategory';
import TestDataStore from 'containers/TestData/TestDataStore';

const routes = [
  {
    name: 'root',
    component: LandingPage,
    path: '/',
    exact: true,
  },

  // customer journey testing
  {
    name: 'pre-sales',
    component: withSubcategory('presales')(CJT),
    path: '/cjt/pre_sales',
    exact: true,
  },
  {
    name: 'registration-and-activation',
    component: withSubcategory('registration-and-activation')(CJT),
    path: '/cjt/registration_and_activation',
    exact: true,
  },
  {
    name: 'order-fultillment',
    component: withSubcategory('e2e')(CJT),
    path: '/cjt/order_fulfillment',
    exact: true,
  },
  {
    name: 'subscription-transaction',
    path: '/cjt/subscription_transaction',
    component: withSubcategory('subscriptions')(CJT),
    exact: true,
  },
  {
    name: 'access',
    path: '/cjt/access',
    component: withSubcategory('access')(CJT),
    exact: true,
  },
  {
    name: 'support',
    path: '/cjt/support',
    component: withSubcategory('support')(CJT),
    exact: true,
  },
  // test data
  {
    name: 'products',
    path: '/testdata/products',
    component: Products,
  },
  {
    name: 'orders',
    path: '/testdata/orders',
    component: Orders,
  },
  {
    name: 'orders',
    path: '/testdata/new_buying_experience',
    component: NewBuying,
  },
  {
    name: 'orders',
    path: '/testdata/buy_sell',
    component: BuySell,
  },
  {
    name: 'simulation',
    path: '/testdata/simulation',
    component: Simulation,
  },
  {
    name: 'test-data-store',
    path: '/testdata/data_store',
    component: TestDataStore,
  },

  // jobs queue
  {
    name: 'jobs-queue',
    path: '/jobsqueue',
    component: JobsQueue,
  },
  {
    name: 'transaction',
    path: '/view/:service/:executionid',
    component: Transaction,
    exact: true,
  },

  // hosting
  {
    name: 'hosting',
    path: '/hosting/:category',
    component: Hosting,
    exact: true,
  },

  // test suites
  {
    name: 'test-suites',
    path: '/testsuites',
    component: TestSuites,
    exact: true,
  },
  {
    name: 'new-test-suite',
    path: '/testsuites/new',
    component: CreateTestSuite,
    exact: true,
  },
  {
    name: 'edit-test-suite',
    path: '/testsuites/:id',
    component: CreateTestSuite,
  },

  // submit pages
  {
    name: 'submit',
    path: '/submit',
    component: Submit,
  },

  // static pages
  {
    name: 'mesh-content',
    path: '/cjt/content',
    component: EndToEndContent,
  },
  {
    name: 'quarry-content',
    path: '/quarry/content',
    component: TestDataContent,
  },
];

export default routes.map((route) => {
  return (
    <Route
      key={route.name}
      path={route.path}
      component={route.component}
      exact={route.exact}
    />
  );
});
